<template>
    <div>
      <box>
        <h1>Perfil de Usuario</h1>
        <div align-h="center">
        <b-card tag="article" style="max-width: 30rem;" class="mb-2" title="Datos">
            <b-card-text>
                <label class="sr-only" for="inline-form-input-username"></label>
                <b-input-group prepend="Usuario" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="$store.state.username" id="inline-form-input-username" disabled/>
                </b-input-group>
                
                <label class="sr-only" for="inline-form-input-names"></label>
                <b-input-group prepend="Nombres" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="$store.state.nombres" id="inline-form-input-names" disabled/>
                </b-input-group>

                <label class="sr-only" for="inline-form-input-lastnames"></label>
                <b-input-group prepend="Apellidos" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="$store.state.apellidos" id="inline-form-input-lastnames" disabled/>
                </b-input-group>

                <label class="sr-only" for="inline-form-input-email"></label>
                <b-input-group prepend="Correo electronico" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="$store.state.email" id="inline-form-input-email" disabled/>
                </b-input-group>

                <label class="sr-only" for="inline-form-input-old-pass"></label>
                <b-input-group prepend="Clave antigua" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="old_pass" id="inline-form-input-old-pass" type="password"/>
                </b-input-group>

                <label class="sr-only" for="inline-form-input-new-pass"></label>
                <b-input-group prepend="Clave nueva" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="new_pass_0" id="inline-form-input-re-pass" type="password"/>
                </b-input-group>

                <label class="sr-only" for="inline-form-input-new-pass"></label>
                <b-input-group prepend="Repetir Clave nueva" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="new_pass_1" id="inline-form-input-re-pass" type="password"/>
                </b-input-group>
    <!--        <b-form-input v-model="text" placeholder="Nombre de usuario"></b-form-input>
                <b-form-input v-model="password" type="password" placeholder="Clave"></b-form-input> -->
            </b-card-text>
    
            <b-button @click="changePass" variant="primary">Cambiar clave</b-button>
        </b-card>
        </div>
      </box>
    </div>
    </template>
    <script>
    import box from '../components/parts/box.vue';
   

    export default {
      name: 'userProfile',
      components: {
        box
      },
      data(){
        return {
          old_pass: null,
          new_pass_0: null,
          new_pass_1: null
           }
      },
      methods: {
        async changePass(){
          if(this.new_pass_0 == this.new_pass_1){
            const body = JSON.stringify({id: this.$store.state.id, old_pass: this.old_pass, new_pass: this.new_pass_0})
            const new_pass = await fetch('/api/user/changepass',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' }, body })
            .then(res => res.json());
            alert(new_pass.message);
          } else { alert('Las claves nuevas no coinciden!')}
        }
        // async loginInfo(data) {
        //   const body = JSON.stringify({ id: data });
        //   const login = await fetch('/api/user/userinfo',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body }).then(res => res.json());
        //   console.log(login)
        //   this.username = login.username;
        //   this.email = login.email;
        //   this.nombres = login.nombres;
        //   this.apellidos = login.apellidos
        // }
      },
      mounted(){
        // this.loginInfo(this.$store.state.id)
        // this.$store.dispatch('getUserInfo', this.$store.state.id)
        // console.log(this.$store.state.id)
      }
    }
    </script>